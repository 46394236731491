.header {
  display: flex;
  flex: 1;
  padding-top: 45px;
  padding-bottom: 45px;
  margin-right: 75px;
  margin-left: 75px;
  max-width: 1200px;
  flex-wrap: wrap;
}
.logoContainer :hover {
  cursor: pointer;
}

.sectionRow {
  background-color: #f8f8f8;
  padding-top: 45px;
  padding-bottom: 45px;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
}
.sectionRow h1 {
  font-size: 24px;
  font-family: 'Source Sans Pro', 'Segoe UI', Frutiger, 'Frutiger Linotype',
    'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  margin-bottom: 14px;
  line-height: 1.1;
  font-weight: normal;
  font-style: normal;
  color: #2b85d7;
  flex: 1;
  text-align: center;
  margin-block-start: 0;
  margin-block-end: 0;
}
.section {
  padding-top: 45px;
  padding-bottom: 45px;
  display: flex;
  flex-direction: row;
  flex: 1;
  text-align: center;
  justify-content: space-around;
  max-width: 1200px;
  flex-wrap: wrap;
}
section {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: row;
  border-bottom: 1px solid #eaeaea;
}
.sectionColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.dashList {
  list-style-type: '- ';
  text-align: left;
}
.headerBar {
  flex: 3;
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.headerButton {
  margin-left: 44px;
}
.userEmail {
  color: #5c6064;
  font-weight: 400;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 13px;
}
