.greyBackground {
  width: 370px;
  background: #f8f8f8;
  padding: 30px;
  margin-top: 18px;
  margin-bottom: 32px;
}

.columnHeader {
  margin: 0px 0;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #000000;
}

.columnText {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #66727c;
}

.error {
  color: #eb5a46;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}
.errorText {
  font-family: Poppins;
  font-size: 12px;
  line-height: 16px;
  color: #eb5a46;
  margin-top: 11px;
}
