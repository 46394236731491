.tileHeader {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-size: 22px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 16px;
}
.tileText {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 1px;
  color: #ffffff;
}
.tileContainer {
  margin: 60px 80px 40px 38px;
  text-align: left;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.section {
  padding-top: 45px;
  padding-bottom: 45px;
  display: flex;
  flex-direction: row;
  flex: 1;
  text-align: center;
  justify-content: space-around;
  max-width: 1200px;
  flex-wrap: wrap;
}
.tileButton {
  color: black;
  font-family: Lato;
  font-weight: 500;
  font-size: 14px;
}
