.text {
  color: #ffffff;
  line-height: 36px;
}

.header {
  margin: 0;
  font-size: 22px;
  text-transform: uppercase;
}
