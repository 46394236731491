.tableHeaderCell {
  background-color: #f8f8f8;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Lato';
}
.tableCell {
  background-color: #f8f8f8;
  color: #66727c;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Roboto';
}
.tableCellBold {
  background-color: #f8f8f8;
  color: #66727c;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Roboto';
}
.listHeader {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 36px;

  text-transform: uppercase;

  color: #646464;
}
.listText {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;

  letter-spacing: 1px;

  color: #242731;
}
.vaccinationsListColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.loader {
  align-self: center;
  padding: 10px;
}
.noVaccinations {
  margin-bottom: 80px;
  margin-top: 30px;
  background-color: #f8f8f8;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 100px;
  padding-top: 100px;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;

  text-align: center;
  letter-spacing: 1px;

  color: #242731;
}
