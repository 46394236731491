.stepHeader {
  font-size: 18px;
  font-weight: bold;
  margin-top: 30px;
  color: rgb(43, 43, 43);
}
.stepHeader2 {
  font-size: 14px;
  font-weight: bold;
  color: rgb(79, 85, 89);
}
.text {
  color: rgb(79, 85, 89);
  font-size: 14px;
}
.dashList {
  list-style-type: "- ";
  text-align: left;
  color: rgb(79, 85, 89);
  font-size: 14px;
}
.sectionRow {
  background-color: #f8f8f8;
  padding-top: 45px;
  padding-bottom: 45px;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
}
.sectionRow h1 {
  font-size: 24px;
  font-family: "Source Sans Pro", "Segoe UI", Frutiger, "Frutiger Linotype",
    "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  margin-bottom: 14px;
  line-height: 1.1;
  font-weight: normal;
  font-style: normal;
  color: #2b85d7;
  flex: 1;
  text-align: center;
  margin-block-start: 0;
  margin-block-end: 0;
}
.section {
  padding-top: 45px;
  padding-bottom: 45px;
  display: flex;
  flex-direction: row;
  flex: 1;
  text-align: center;
  justify-content: space-around;
  max-width: 1200px;
  flex-wrap: wrap;
}
section {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: row;
  border-bottom: 1px solid #eaeaea;
}
.sectionColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
