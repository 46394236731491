.redText {
  color: #eb5a46;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.redText h6,
ul {
  margin: 0;
}

.normalText {
  font-family: Lato, serif;
  font-style: normal;
  font-size: 14px;
}

.listText {
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 1px;
  color: #242731;
}

.listText > ul {
  margin: 4px;
}

.normalHeader {
  margin: 0 0 12px 0;
  font-family: Lato, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #505559;
}
