.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin-top: 50px;
  margin-bottom: 184px;
}

.formContainer {
  flex: 1;
  text-align: left;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.formContainer h1 {
  flex: 1;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 36px;
  text-transform: uppercase;
  color: #646464;
  margin-block-start: 0;

  margin-bottom: 32px;
}
.formContainer p {
  margin-block-start: 0;
  margin-bottom: 0;

  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;

  letter-spacing: 1px;

  color: #242731;
}
.formContainer .text1 {
  margin-bottom: 25px;
}
.formContainer h2 {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;

  color: #505559;
  margin-top: 32px;
  margin-bottom: -25px;
}
.submitButton {
  margin-right: 10px;
}
.errorText {
  font-family: Poppins;
  font-size: 12px;
  line-height: 16px;
  color: #eb5a46;
  margin-top: 11px;
}
.submitButtonRow {
  margin-top: 39px;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
