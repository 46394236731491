.sectionColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.footerHeaderRow {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.footerHeaderColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.footerHeader {
  padding-top: 43px;
  text-align: left;
  font-weight: 600;
  font-size: 18px;
  color: #2b2b2b;
  line-height: 23.4px;
}
.footerHeader2 {
  text-align: left;
  font-size: 12px;
  color: rgba(43, 43, 43, 0.8);
  text-transform: uppercase;
  margin-bottom: 20px;
  line-height: 1.3;
}
.footerText {
  flex: 1;
  text-align: left;
  color: #4f5559;
  line-height: 1.6;
  font-size: 14px;
}
.footerBoldItalicText {
  font-weight: bold;
  font-style: italic;
  margin-top: 12px;
}
