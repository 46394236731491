.container {
  flex: 1;
  text-align: left;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.container .text1 {
  margin-bottom: 25px;
}

.container h1 {
  flex: 1;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 36px;
  text-transform: uppercase;
  color: #646464;
  margin-block-start: 0;

  margin-bottom: 32px;
}
.container p {
  margin-block-start: 0;
  margin-bottom: 0;

  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;

  letter-spacing: 1px;

  color: #242731;
}
