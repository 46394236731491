.header {
    font-weight: 700;
    font-size: 22px;
    line-height: 36px;
    color: #646464;
    text-transform: uppercase;
    font-family: Source Sans Pro, serif;
    margin-bottom: 0;
}

.text {
    margin: 0;
    font-family: Lato, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 1px;

    color: #242731;
}