.container {
    width: 100%;
    margin-top: 61px;
}

.input {
    border-radius: 4px;
    position: relative;
    background-color: white;
    font-size: 16px;
    margin: 0;
}

.input input {
    padding: 10px 25px;
}

.label {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #2B2B2B;
    transform: none;
    top: -25px;
    width: 100%;
}

.softText {
    color: #575F6E;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
}

.helperText {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #000000;
    margin-left: 0;
}

.icon {
    position: absolute;
    right: 10px;
    top: 22px;
}

.icon svg {
    width: 25px;
    height: 25px;
}