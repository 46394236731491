.customButton {
  background-color: white;
  color: #5c6064;
  font-weight: 500;
  border-radius: 0;
  width: 185px;
  height: 48px;
  text-transform: none;
}
.customButton:hover {
  background-color: white;
}
