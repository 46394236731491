.container {
  width: 100%;
  margin-top: 61px;
}
.label {
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #2b2b2b;
  transform: none;
  top: -25px;
  width: 100%;
}
.softText {
  color: #575f6e;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
}
.input {
  border-radius: 4px;
  position: relative;
  background-color: white;
  font-size: 16px;
  margin: 0;
}
.input input {
  padding: 10px 25px;
}

.helperText {
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #000000;
  margin-left: 0;
}
