.footerHeaderColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.footerHeader {
  padding-top: 43px;
  text-align: left;
  font-weight: 600;
  font-size: 18px;
  color: #2b2b2b;
  line-height: 23.4px;
}
.footerHeader2 {
  text-align: left;
  font-size: 12px;
  color: rgba(43, 43, 43, 0.8);
  text-transform: uppercase;
  margin-bottom: 20px;
  line-height: 1.3;
}
.footerText {
  flex: 1;
  text-align: left;
  color: #4f5559;
  line-height: 1.6;
  font-size: 14px;
}
.footerBoldItalicText {
  font-weight: bold;
  font-style: italic;
  margin-top: 12px;
}
.footerHeaderRow {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.grayFooter {
  flex: 1;
  display: flex;
  background-color: rgb(50, 55, 58);
  padding: 18px 0 10px 0;
  flex-direction: row;
  justify-content: center;
}
.grayFooterRow {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin: 0 91px 0 91px;
  max-width: 1200px;
}
.grayFooterText {
  font-size: 12px;
  line-height: 2.5;
  color: #8b9499;
}

.sectionRow {
  background-color: #f8f8f8;
  padding-top: 45px;
  padding-bottom: 45px;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
}
.sectionRow h1 {
  font-size: 24px;
  font-family: "Source Sans Pro", "Segoe UI", Frutiger, "Frutiger Linotype",
    "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  margin-bottom: 14px;
  line-height: 1.1;
  font-weight: normal;
  font-style: normal;
  color: #2b85d7;
  flex: 1;
  text-align: center;
  margin-block-start: 0;
  margin-block-end: 0;
}
.section {
  padding-top: 45px;
  padding-bottom: 45px;
  display: flex;
  flex-direction: row;
  flex: 1;
  text-align: center;
  justify-content: space-around;
  max-width: 1200px;
  flex-wrap: wrap;
}
section {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: row;
  border-bottom: 1px solid #eaeaea;
}
.sectionColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.image {
  margin-bottom: 20px;
}
.logoFooter {
  width: 29px;
  height: 31px;
  margin-right: 15px;
}
