.header {
  font-weight: 700;
  font-size: 22px;
  line-height: 36px;
  color: #646464;
  text-transform: uppercase;
  font-family: Source Sans Pro, serif;
}

.link {
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
  color: #4598d0;
}

.softText {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #575f6e;
}

.checkboxText {
  font-size: 10px;
  letter-spacing: 0.05em;
  color: #66727c;
}

.checkboxLink {
  font-size: 10px;
  letter-spacing: 0.05em;
  color: #eb5a46;
  text-decoration: none;
}

.checkboxLink:hover {
  color: #4598d0;
}

.checkboxLabel {
  line-height: 15px;
}

.redText {
  color: #eb5a46;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}
