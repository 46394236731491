.aboutTagHeaderContainer {
  text-align: left;
  padding-bottom: 12px;
  padding-top: 12px;
  background-color: #36bae2;
  border-radius: 4px;
  color: white;
  padding-right: 16px;
  padding-left: 16px;
}
.aboutTagDashList {
  list-style-type: "- ";
  text-align: left;
  color: #4f5559;
  font-size: 14px;
}
.aboutTagNumberingHeader {
  font-size: 18px;
  font-weight: bold;
  color: #4f5559;
  margin-bottom: 20px;
}
.aboutRabieski {
  text-align: left;
  color: "#3d3d3d";
  font-size: 14px;
}

.sectionRow {
  background-color: #f8f8f8;
  padding-top: 45px;
  padding-bottom: 45px;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
}
.sectionRow h1 {
  font-size: 24px;
  font-family: "Source Sans Pro", "Segoe UI", Frutiger, "Frutiger Linotype",
    "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  margin-bottom: 14px;
  line-height: 1.1;
  font-weight: normal;
  font-style: normal;
  color: #2b85d7;
  flex: 1;
  text-align: center;
  margin-block-start: 0;
  margin-block-end: 0;
}
.section {
  padding-top: 45px;
  padding-bottom: 45px;
  display: flex;
  flex-direction: row;
  flex: 1;
  text-align: center;
  justify-content: space-around;
  max-width: 1200px;
  flex-wrap: wrap;
  justify-content: center;
  padding: 25px;
}
section {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: row;
  border-bottom: 1px solid #eaeaea;
}
.sectionColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
}
.aboutTagRow {
  max-width: 1000px;
  flex: 1;
  display: flex;
  flex-direction: row;
}
.accordionDetailsText {
  font-size: 14px;
  font-family: Lato;
  text-align: justify;
}
.accordionDetailsContainer {
  background-color: white;
}
.accordionSummary {
  color: white;
}
.accordion {
  background-color: #36bae2;
}
.aboutTagHeaderText {
  font-family: Lato;
}
.sectionColumn5 {
  flex: 5;
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
}
