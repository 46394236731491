.sectionRow {
  background-color: #f8f8f8;
  padding-top: 45px;
  padding-bottom: 45px;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
}
.sectionRow h1 {
  font-size: 24px;
  font-family: "Source Sans Pro", "Segoe UI", Frutiger, "Frutiger Linotype",
    "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  margin-bottom: 14px;
  line-height: 1.1;
  font-weight: normal;
  font-style: normal;
  color: #2b85d7;
  flex: 1;
  text-align: center;
  margin-block-start: 0;
  margin-block-end: 0;
}

section {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: row;
  border-bottom: 1px solid #eaeaea;
}
.sectionColumn {
  flex-direction: column;
  width: 1200px;
}
