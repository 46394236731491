.loginForm {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 500px;
}
.loginInput {
  height: 40px;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 23px;

  margin-top: 5px;
  margin-bottom: 32px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.05em;
  display: flex;
}
.passwordInput {
  height: 40px;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  padding-left: 23px;
  border-radius: 4px;
  margin-top: 5px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.05em;
}

.loginContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 250px;
  margin-top: 50px;
}
.loginFormHeader {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
}
.loginFormHeaderText1 {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 36px;
  text-transform: uppercase;
  color: #646464;
}
.loginFormHeaderText2 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #575f6e;
  margin-right: 6px;
}
.loginFormHeaderLink {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #4598d0;
}
.loginFormHeaderLink:hover {
  cursor: pointer;
}
.loginFormHeaderRightContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.loginInputLabel {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.05em;

  color: #2b2b2b;
}
.submitButton {
  margin-right: 10px;
}
.submitButtonRow {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
