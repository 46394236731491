.customButton {
  background-color: #4598d0;
  color: white;
  border-radius: 0;
  font-weight: 500;
  width: 185px;
  height: 48px;
  text-transform: none;
}
.customButton:hover {
  background-color: #35508b;
}
